import { UtilsService } from 'src/app/core/utils-service/utils.service';
import { AliceService } from '../../../core/alice-service/alice.service';
import { Chat, MessageAssistant } from '../../../core/interfaces/chat';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Unsubscribable } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgStyle } from '@angular/common';
import { AliceAnimationsComponent } from '../../alice-animations/alice-animations.component';

@Component({
  selector: 'app-alice-chat',
  templateUrl: './alice-chat.component.html',
  styleUrls: ['./alice-chat.component.scss'],
  standalone: true,
  imports: [MatIcon, NgClass, NgStyle, AliceAnimationsComponent],
})
export class AliceChatComponent implements OnInit, OnDestroy {
  chat?: Chat;
  last_message?: MessageAssistant;
  last_message_subscription?: Unsubscribable;

  chat_opened = false;
  special_message = false;

  random_skeletons_length = new Array<number>();
  animating_message = false;

  aliceChatStartSubscription?: Subscription;
  alice_status: 'sent' | 'deleyed' | 'timeout' | 'error' | 'success' | undefined = undefined;

  readonly delayed_timeout = 20 * 1000; // Milliseconds
  readonly error_timeout = 40 * 1000; // Milliseconds

  _deleyed_timeout?: ReturnType<typeof setTimeout>;
  _error_timeout?: ReturnType<typeof setTimeout>;

  element?: HTMLElement;

  constructor(
    private aliceService: AliceService,
    private utils: UtilsService
  ) {}

  ngOnInit(): void {
    this.aliceChatStartSubscription = this.aliceService.onChatStart.subscribe((chat: Chat) => {
      this.chat = chat;
      this.chat.generating_answer = true;
      this.alice_status = 'sent';
      this.chat_opened = true;

      this.element = document.getElementById('alice-chat-content') || undefined;

      this.utils.sendEvent('cl_gpt_smallchatv1');

      if (chat.type == 'question') this.utils.sendEvent('cl_gpt_smallchatv1_explanation');
      else if (chat.type == 'simulation_tip') this.utils.sendEvent('cl_gpt_smallchatv1_simulation_tip');

      // Generate 6 random skeletons with random length from 80% to 100%
      this.random_skeletons_length = new Array<number>();
      for (let i = 0; i < 6; i++) {
        this.random_skeletons_length.push(Math.floor(Math.random() * 20 + 80));
      }

      // this.aliceService.generateMessageChat(chat.question_id, 'start').then(message => this.newMessage(message));
      this.onMessageSent();
      this.last_message_subscription = this.aliceService
        .generateMessageChat(this.chat.question, 'start', chat.type, '', [], chat.preparation_level)
        .subscribe(msg => {
          this.newMessage(msg);
        });
    });
  }

  ngOnDestroy() {
    this.aliceChatStartSubscription?.unsubscribe();
    this.last_message_subscription?.unsubscribe();
  }

  onMessageSent() {
    if (!this.chat) return;

    this.alice_status = 'sent';

    // Delayed
    this._deleyed_timeout = setTimeout(() => {
      this.alice_status = 'deleyed';
    }, this.delayed_timeout);

    // Error
    this._error_timeout = setTimeout(() => {
      this.alice_status = 'error';
    }, this.error_timeout);
  }

  newMessage(message: MessageAssistant) {
    if (!this.chat) return;
    this.alice_status = 'success';

    if (this._deleyed_timeout) clearTimeout(this._deleyed_timeout);
    if (this._error_timeout) clearTimeout(this._error_timeout);

    message.animating_answer = true;
    message.animated = false;
    this.chat.generating_answer = false;
    const character = this.additionalResponse();
    this.special_message = false;
    if (character && message.possibile_answers) {
      this.special_message = true;
      message.possibile_answers.push('Spiegamelo come se fossi ' + character);
    }

    this.last_message = message;

    this.chat.messages.push(message);
    this.scrollBottom();
    this.animateMessage(this.last_message);
  }

  animateMessage(message: MessageAssistant) {
    const original_content = message.content;
    message.content = '';
    message.animating_answer = true;
    this.animating_message = true;

    const interval = setInterval(() => {
      if (original_content.length - message.content.length > 5) {
        console.log({ message: message });
        const random_size = 1; //Math.floor(Math.random() * 5 + 5);
        message.content += original_content.slice(message.content.length, message.content.length + random_size);
        this.scrollBottom();
      } else {
        message.content = original_content;
        message.animating_answer = false;
        message.animated = true;
        this.animating_message = false;
        if (this.last_message) {
          this.last_message.animated = true;
          this.last_message.animating_answer = false;
        }
        this.scrollBottom();

        setTimeout(() => {
          this.scrollBottom();
        }, 100);

        clearInterval(interval);
      }
    }, 5);
  }

  scrollBottom() {
    if (!this.element) return;

    this.element.scrollTop = this.element.scrollHeight;
  }

  additionalResponse(): string | undefined {
    const characters = [
      'Dante Alighieri --> come se stesse narrando la Divina Commedia',
      'Donald Trump --> come se stesse tenendo un comizio pre elettorale parlando in italiano',
      'Roberto Benigni --> come se stesse narrando un canto della divina commedia',
      'un pirata --> come se stesse raccontando una storia di pirati alla sua ciurma',
      'Sfera Ebbasta --> che trappa in italiano ad un suo concerto',
      'Chiara Ferragni --> che parla ad una platea di influencer per presentare la sua nuova collezione',
      'Fabri Fibra --> che rappa in italiano ad un suo concerto',
      'Guè Pequeno --> che rappa in italiano ad un suo concerto',
      'Giulio Cesare --> che parla ad una platea di romani',
      'Drake --> che canta in italiano',
      'Gianni Morandi --> che parla ad un suo concerto dell amore e della vita',
      'Greta Thunberg --> che parla in italiano ad una pubblico facendo molti richiami al clima',
      'Francesco Totti --> che parla in romano ad un gruppo di tifosi della Roma',
      'il Cappellaio Matto --> come se stesse parlando con Alice nel paese delle meraviglie e le stesse raccontando la sua storia',
      'Il commissario Montalbano --> che indaga in un paesino siciliano per risolvere un caso',
      'Magellano --> che parla al suo equipaggio durante la sua traversata del globo',
      'Piero angela --> che spiega come se stesse spiegando ad una sua puntata di superquark',
      'Paolo Bonolis --> come se stesse conducendo una puntata di avanti un altro con il suo stile di conduzione ironico e spiritoso',
      'Papa Francesco --> che parla in italiano ad una platea di fedeli',
      'Harry Potter --> come se stesse parlando con altri studenti di Hogwarts parlando di magia in italiano',
      'Joe Bastianich --> come se stesse parlando a Masterchef, insieme agli altri giudici Cannavacciuolo, Barbieri e Cracco. Quando si riferisce agli altri giudici li chiama sempre per nome ',
    ];
    const PERCENT_OF_CHANCE = 0; // Normalized between 1 - 0
    if (Math.random() > PERCENT_OF_CHANCE) return undefined;

    return characters[Math.floor(Math.random() * characters.length)];
  }

  hideMessageInformation(message: string): string {
    return message.split('-->')[0].trim();
  }

  openInfoDialog() {
    if (!this.chat) return;
    this.aliceService.infoDialog(this.chat.type as 'question' | 'simulation_tip');
  }

  response(message: string) {
    if (!this.chat) return;

    this.chat.messages.push({
      role: 'user',
      content: message,
    });

    if (this.chat.type == 'question') this.utils.sendEvent('cl_gpt_moreexplanation');

    this.chat.generating_answer = true;
    this.last_message = undefined;
    this.alice_status = 'sent';
    this.onMessageSent();

    this.last_message_subscription = this.aliceService
      .generateMessageChat(this.chat.question, 'response', this.chat.type, message, this.chat.messages)
      .subscribe(msg => {
        this.newMessage(msg);
      });
  }

  stopChat() {
    this.chat_opened = false;
    this.alice_status = undefined;
    this.aliceService.onChatClose.next(this.chat!);
    this.aliceService.save(this.chat!).subscribe({ error: err => console.log(err) });
    if (this.last_message_subscription) this.last_message_subscription.unsubscribe();
    setTimeout(() => {
      this.last_message = undefined;
      this.chat = undefined;
    }, 300);
  }
}
