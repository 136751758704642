<fut-base-dialog>
  <fut-icon-title
    [state]="state()"
    [iconTitle]="{ text: 'dialogs.check-vertical.title', translate: true }"
    image="/assets/emoji/graduation_cap.svg"
    [subtitle]="subtitle()"></fut-icon-title>
  <div *transloco="let translate" class="d-flex justify-content-end fut-m-t-24">
    <div class="d-flex fut-gap-8">
      <button class="fut-btn fut-btn-link" (click)="closeDialog()">
        {{ translate('dialogs.check-vertical.action.remain', { currentVertical: currentVertical() }) }}
      </button>
      <button class="fut-btn fut-btn-accent" (click)="changeVertical()">
        {{ translate('dialogs.check-vertical.action.change', { cookieVertical: verticalCookieName() }) }}
      </button>
    </div>
  </div>
</fut-base-dialog>
