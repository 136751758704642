import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaskModule } from 'ngx-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SwiperModule } from 'swiper/angular';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { provideTransloco } from '@jsverse/transloco';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './view/auth/auth.module';
import { HomeModule } from './view/home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeIt from '@angular/common/locales/it';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LottieComponent, provideLottieOptions } from 'ngx-lottie';

import { GoogleVerificationComponent } from './view/google-verification/google-verification.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { ConfigService } from './core/config/config.service';
import { Observable } from 'rxjs';
import { SpinnerInitializerService } from './components/spinner/spinner-initializer.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FilesDownloadComponent } from './shared/download/component/files-download/files-download.component';
import { futuraInterceptor } from './core/interceptor/futura/futura.interceptor';
import { TranslocoHttpLoader } from './transloco-loader';
import { HistoryService, provideFuturaAnalytics } from '@futura/futura-ui';
import { UtilsService } from './core/utils-service/utils.service';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';

registerLocaleData(localeIt, 'it');

export function initConfig(config: ConfigService): () => Observable<boolean> {
  return () => config.init();
}

export function initSpinner(spinnerInitializerService: SpinnerInitializerService): () => void {
  return () => spinnerInitializerService.init();
}

@NgModule({
  declarations: [AppComponent, SpinnerComponent, GoogleVerificationComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    HomeModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.google_analytics_key),
    NgxGoogleAnalyticsRouterModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxMaskModule.forRoot({
      validation: false,
    }),
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    SwiperModule,
    ServiceWorkerModule.register('futura-service-worker.js', {
      enabled: environment.production,
      // enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    DragDropModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    ProgressbarModule,
    TypeaheadModule,
    FilesDownloadComponent,
    LottieComponent,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase(initializeApp(environment.firebase), environment.firebase.databaseURL)),
    { provide: LOCALE_ID, useValue: localStorage.getItem('language') || environment.locale },
    provideTransloco({
      config: {
        availableLangs: ['en', 'it'],
        defaultLang: 'it',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: true, // disable console warnings
      },
      loader: TranslocoHttpLoader,
    }),
    DatePipe,
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        it: 'it-IT',
      },
      defaultLocale: 'it-IT',
    }),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [HistoryService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [ConfigService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initSpinner,
      multi: true,
      deps: [SpinnerInitializerService],
    },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideHttpClient(withInterceptors([futuraInterceptor])),
    provideFuturaAnalytics(UtilsService),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
