<div class="main-container auth-container justify-content-center">
  <div class="background"></div>
  <div class="container-fluid h-100 d-none">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-lg-10 h-100">
        <div class="row h-100">
          <div class="col-1"></div>
          <div class="col-12 col-lg-5 h-auto h-lg-100">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center justify-content-lg-end mr-0 mr-lg-5 pr-0 pr-lg-2">
              <div class="w-100 d-flex flex-column align-items-center">
                <div *transloco="let translate" class="auth-card">
                  <h1 [futEditable]="'auth.login.sidebox.title'" class="fut-h1">
                    {{ translate('auth.login.sidebox.title') }}
                  </h1>
                  <!-- <span [futEditable]="'auth.login.sidebox.subtitle'" class="d-block d-lg-none">
                    {{ translate('auth.login.sidebox.subtitle') }}
                  </span> -->
                  <div class="features-list">
                    @for (auth_point of AUTH_POINT_LIST; track auth_point) {
                      <div class="d-flex">
                        <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
                        <span [futEditable]="'auth.list.points-' + auth_point" [innerHTML]="translate('auth.list.points-' + auth_point)"></span>
                      </div>
                    }
                  </div>
                  <div class="fut-m-t-24">
                    <span [innerHTML]="translate('auth.change-vertical.dialog.open', { vertical: currentVertical() })" (click)="changeVertical()"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 d-none d-lg-block"></div>
          <div class="col-12 col-lg-4 h-auto h-lg-100">
            <div class="w-100 h-100 d-flex align-items-start align-items-lg-center justify-content-center justify-content-lg-start">
              <div class="fut-card">
                <div class="d-flex flex-column w-100">
                  <ng-container *transloco="let translate">
                    <div class="d-flex flex-column align-items-center">
                      <div class="mb-2 fut-logo">
                        <img [src]="authLogo || '../../../../assets/futura-logo-blu.svg'" alt="Futura" />
                      </div>
                      <h2 class="mb-5 text-center" futEditable="auth.login.title" style="max-width: 17em">{{ translate('auth.login.title') }}</h2>
                    </div>
                    <h3 [futEditable]="'auth.login.form.title'" class="fut-h3 mb-0">{{ translate('auth.login.form.title') }}</h3>
                    <span [futEditable]="'auth.login.form.subtitle'" class="mb-4">{{ translate('auth.login.form.subtitle') }}</span>
                  </ng-container>
                  <form (submit)="login()" [formGroup]="phoneForm" class="w-100 d-flex flex-column">
                    <fut-phone-input formControlName="phone" placeholder="Numero di telefono" [showErrorMessage]="false"></fut-phone-input>
                    <span [futEditable]="'auth.login.form.otp_explanation'" class="fut-small mt-3 mb-3">{{
                      'auth.login.form.otp_explanation' | transloco
                    }}</span>
                    <button class="fut-btn fut-btn-accent mt-4 w-100" id="submit-button" tabindex="0" type="submit">
                      {{ 'auth.login.form.submit' | transloco }}
                    </button>
                    <span (click)="privacyPolicy()" [futEditable]="'auth.login.privacy_policy'" class="mt-3 fut-small pointer">{{
                      'auth.login.privacy_policy' | transloco
                    }}</span>
                  </form>
                </div>
                <div class="auth-footer-info"></div>
              </div>
            </div>
          </div>
          <div class="col-1"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="login-navbar d-none">
    <img [src]="authLogo || '../../../../assets/futura-logo-blu.svg'" alt="Futura" />
    <h1 class="m-0 fut-h1" [futEditable]="'auth.login.navbar.title'">{{ 'auth.login.navbar.title' | transloco }}</h1>
  </div>
  <div class="w-100 h-100 justify-content-center align-items-center d-none">
    <div class="w-100 d-flex justify-content-center align-items-center" style="gap: 20em">
      <div *transloco="let translate" class="auth-card h-100">
        <h1 [futEditable]="'auth.login.sidebox.title'" class="fut-h1">
          {{ translate('auth.login.sidebox.title') }}
        </h1>
        <!-- <span [futEditable]="'auth.login.sidebox.subtitle'" class="d-block d-lg-none">
                    {{ translate('auth.login.sidebox.subtitle') }}
                  </span> -->
        <div class="features-list">
          @for (auth_point of AUTH_POINT_LIST; track auth_point) {
            <div class="d-flex">
              <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
              <span [futEditable]="'auth.list.points-' + auth_point" [innerHTML]="translate('auth.list.points-' + auth_point)"></span>
            </div>
          }
        </div>
        <span class="d-block mt-4">Non ti stai preparando per {{ vertical$ | async }}? <u class="fw-bold pointer">Clicca qui</u></span>
      </div>
      <div class="fut-card">
        <div class="d-flex flex-column w-100">
          <ng-container *transloco="let translate">
            <!-- <div class="d-flex flex-column align-items-center">
              <div class="mb-2 fut-logo">
                <img [src]="authLogo || '../../../../assets/futura-logo-blu.svg'" alt="Futura" />
              </div>
              <h2 class="mb-5 text-center" futEditable="auth.login.title" style="max-width: 17em">{{ translate('auth.login.title') }}</h2>
            </div> -->
            <h1 [futEditable]="'auth.login.form.title'" class="fut-h1 d-block text-center">{{ translate('auth.login.form.title') }}</h1>
            <span [futEditable]="'auth.login.form.subtitle'" class="mb-4 d-block text-center">{{ translate('auth.login.form.subtitle') }}</span>
          </ng-container>
          <form (submit)="login()" [formGroup]="phoneForm" class="w-100 d-flex flex-column">
            <fut-phone-input formControlName="phone" placeholder="Numero di telefono" [showErrorMessage]="false"></fut-phone-input>
            <span [futEditable]="'auth.login.form.otp_explanation'" class="fut-small mt-3 mb-3">{{ 'auth.login.form.otp_explanation' | transloco }}</span>
            <button class="fut-btn fut-btn-accent mt-4 w-100" id="submit-button" tabindex="0" type="submit">
              {{ 'auth.login.form.submit' | transloco }}
            </button>
            <span (click)="privacyPolicy()" [futEditable]="'auth.login.privacy_policy'" class="mt-3 fut-small pointer">{{
              'auth.login.privacy_policy' | transloco
            }}</span>
          </form>
        </div>
        <div class="auth-footer-info"></div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="d-flex justify-content-center">
      <div class="cards-container">
        <div class="logo-container d-flex flex-column flex-lg-row justify-content-center align-items-center fut-gap-12 mb-4">
          <img [src]="authLogo || '../../../../assets/futura-logo-blu.svg'" alt="Futura" />
          <h1 class="m-0 fut-h1 text-white text-center" [futEditable]="'auth.login.navbar.title'">{{ 'auth.login.navbar.title' | transloco }}</h1>
        </div>
        <app-general-section [type]="'light'" [isCard]="!tabletMode()">
          <div class="row">
            <div class="col-12 col-lg-7 d-flex justify-content-center align-items-center mb-4 mb-lg-0">
              <div *transloco="let translate" class="auth-card w-100 h-100">
                <h4 class="fut-h4 mb-3 d-block fut-primary-200" [futEditable]="'auth.login.sidebox.pretitle'">
                  {{ translate('auth.login.sidebox.pretitle') }}
                </h4>
                <h3 [futEditable]="'auth.login.sidebox.title'" class="fut-h3">
                  {{ translate('auth.login.sidebox.title') }}
                </h3>
                <span [futEditable]="'auth.login.sidebox.subtitle'" class="fut-primary-200 d-block d-lg-none">
                  {{ translate('auth.login.sidebox.subtitle') }}
                </span>
                <div class="features-list">
                  @for (auth_point of AUTH_POINT_LIST; track auth_point) {
                    <div class="d-flex fut-primary-200">
                      <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
                      <span [futEditable]="'auth.list.points-' + auth_point" [innerHTML]="translate('auth.list.points-' + auth_point)"></span>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-5 d-flex justify-content-center align-items-center">
              <app-general-section [type]="'light'" class="w-100" [isCard]="tabletMode()">
                <div class="d-flex flex-column justify-content-center align-items-center h-100 w-100 px-0 px-lg-4">
                  <ng-container *transloco="let translate">
                    <!-- <div class="d-flex flex-column align-items-center">
              <div class="mb-2 fut-logo">
                <img [src]="authLogo || '../../../../assets/futura-logo-blu.svg'" alt="Futura" />
              </div>
              <h2 class="mb-5 text-center" futEditable="auth.login.title" style="max-width: 17em">{{ translate('auth.login.title') }}</h2>
            </div> -->
                    <h1 [futEditable]="'auth.login.form.title'" class="fut-h1 d-block mb-4 text-center">{{ translate('auth.login.form.title') }}</h1>
                    <!-- <span [futEditable]="'auth.login.form.subtitle'" class="mb-4 d-block text-center">{{ translate('auth.login.form.subtitle') }}</span> -->
                  </ng-container>
                  <form (submit)="login()" [formGroup]="phoneForm" class="w-100 d-flex flex-column">
                    <fut-phone-input formControlName="phone" placeholder="Numero di telefono" [showErrorMessage]="false"></fut-phone-input>
                    <span [futEditable]="'auth.login.form.otp_explanation'" class="fut-small mt-2 mb-3 fut-primary-200">{{
                      'auth.login.form.otp_explanation' | transloco
                    }}</span>
                    <button class="fut-btn fut-btn-accent mt-4 w-100" id="submit-button" tabindex="0" type="submit">
                      {{ 'auth.login.form.submit' | transloco }}
                    </button>
                    <span (click)="privacyPolicy()" [futEditable]="'auth.login.privacy_policy'" class="mt-2 fut-small pointer fut-primary-200">{{
                      'auth.login.privacy_policy' | transloco
                    }}</span>
                  </form>
                </div>
              </app-general-section>
            </div>
          </div>
        </app-general-section>
        <div class="d-flex justify-content-center align-items-center w-100 mt-2">
          <span class="d-block text-white fut-body" (click)="changeVertical()"
            >Non ti stai preparando per {{ vertical$ | async }}? <u class="fw-bold pointer">Clicca qui</u></span
          >
        </div>
      </div>
    </div>
  </div>
</div>
