<fut-base-dialog>
  <fut-icon-title
    [state]="state()"
    [iconTitle]="{ text: 'dialogs.change-vertical.title', translate: true }"
    image="/assets/materials/alice.png"
    [subtitle]="{ text: 'dialogs.change-vertical.subtitle', translate: true }"></fut-icon-title>
  <div *transloco="let translate" class="d-flex flex-column fut-gap-24 fut-m-t-24">
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <mat-label>
        {{ translate('dialogs.change-vertical.label') }}
      </mat-label>
      <mat-select [(ngModel)]="selectedVertical" class="w-100" placeholder="{{ translate('dialogs.change-vertical.selection.placeholder') }}">
        @for (vertical of verticalsFiltered(); track vertical) {
          <mat-option [value]="vertical.value">{{ vertical.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <button class="fut-btn fut-btn-accent w-100" [disabled]="!selectedVertical()" (click)="changeVertical()">
      {{ translate('dialogs.change-vertical.action.start') }}
    </button>
  </div>
</fut-base-dialog>
